* {
  padding: 0px;
  margin: 0%;
}

.container {
  display: inline-block;
  padding: 0px 15px;
  text-align: left;
  height: 10px;
} 

.container-small {
  display: inline-block;
  padding: 0px 5px;
  text-align: left;
  height: 10px;
} 


.thumbnail {
  height: 150px;
  width: 225px;
  padding: 0px;
  border-radius: 10px;
}


.thumbnail-title {
  
  padding: 0px 0px 15px 0px;
  margin: 0px;
  overflow: hidden;
  font-size: medium;
  width: 225px;
  height: 40px;
}



.thumbnail-small{
  height: 75px;
  width: 112px;
  padding: 0px;
  border-radius: 10px;
}


.thumbnail-title-small {
  
  padding: 0px 0px 15px 0px;
  margin: 0px;
  overflow: hidden;
  font-size: medium;
  width: 112px;
  height: 20px;
}

.ifyes {
  background-color: green;
}

.ifno {
  background-color: red;
}

.searchitem-container {
  padding: 10px;
  
}

label {
  display: inline;
  padding: 5px
}

input {
  display: inline-box;
}

.search-container {
  margin: auto;
  max-width: 1275px;
  padding-bottom: 30px;
  padding-top: 60px;




  }

.option {
  display: inline-block;

  
}

.searchbutton {
  width: 600px;
  height: 50px;
  margin: auto;

}
.randombutton {
  height: 60px;
  width: 60px;
  margin: 7px 7px 0px 7px;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
}

.option-container {
  text-align: center;
}

h1 {
  text-align: center;
  padding: 10px; 
  padding-bottom: 25px;
  font-size: 50px;
  font-family: parisienne, serif;
}

h3 {
  padding-top: 20px;
  padding-bottom: 50px;
  color: rgb(41, 41, 41);
  text-align: center;
}

.heading {
 width: 100%;
 text-align: center;
 background-color: white;
}

.player {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 25px;
  max-width: 800px; 
  margin: auto;
  display: block;
   
}
.footer {
  min-height: 500px;
  background-color: rgb(1, 1, 34);
  color: white;


}

.footer-text {
  padding: 20px;
  max-width: 1300px;
  height: auto;
  margin: auto;
}

.searchimage {
  height: 60px;
  width: 60px;
  margin: 7px 7px 0px 7px;
  background-color: rgb(230, 230, 230);
  border-radius: 10px;
}

.searchimage-active {
  height: 60px;
  width: 60px;
  margin: 7px 7px 0px 7px;
  background-color: rgb(120, 225, 120);
  border-radius: 10px;
}

.searchtext {
  display: block;
}

.playertitle {
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 50px; 
}

.attributelink {
  color: white;
  display: block;
  text-align: center;
}

a {
  color: black;
  text-decoration: none;
  font-weight: 800;
}